@import '../_include/variables';
@import '../_include/decoration';

@media screen and (min-width: @responsive-threshold) {
  footer{
    width: 756px;
    height: 50px;
    line-height: 50px;
    color: @color-white;
    font-size: 16px;
    background-color: @color-line-green;
    text-align: center;
    margin-left: -30px;
    a{
      color: @color-white;
    }
  }
}

@media screen and (max-width: (@responsive-threshold - 1)) {
  footer{
    width: 100vw;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.4;
    color: @color-white;
    font-size: 12px;
    background-color: @color-line-green;
    text-align: center;
    margin-left: -20px;
    a{
      color: @color-white;
    }
  }
}

@import '../_include/variables';
@import '../_include/decoration';

@media screen and (min-width: @responsive-threshold){
  section.nav.fixed{
    position: fixed;
    top: 0;
    left: auto;
  }
  section.nav{
    width: 256px;
    box-sizing: border-box;
    padding-top: 35px;
    float: left;
    div.inner-nav{
      width: 100%;
      figure.logo{
        display: block;
        width: 244px;
        height: 44px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 35px;
        background-image: url("@{path-svg}logo.svg");
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
      }
      nav{
        margin-bottom: 40px;
        ul{
          li{
            margin-left: 15px;
            margin-bottom: 15px;
            position: relative;
            cursor: pointer;
            height: 36px;
            display: flex;
            flex-direction: row;
            align-items: center;
            span{
              position: relative;
              z-index: 1;
              color: @color-white;
              font-size: 18px;
              left: 8px;
              font-family: Maru Folk Bold;
              bottom: 1px;
            }
            &:after{
              display: block;
              content: "";
              background-color: @color-line-green;
              border-radius: 7px 0 0 7px;;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              transform: scaleX(0);
              transition-duration: 0.5s;
              transform-origin: right;
              transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
              transition-property: transform;
            }
            &:hover:after{
              transform: none;
            }
            &.active:after{
              transform: none;
            }
          }
        }
      }
      div.sns{
        margin-left: 20px;
        overflow: hidden;
        div{
          float: left;
          width: 80px;
          margin-right: 10px;
          &.hatena{
            margin-top: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: (@responsive-threshold - 1)){
  section.nav{
    display: none;
  }
}

@import '../_include/variables';
@import '../_include/decoration';

@media screen and (min-width: @responsive-threshold) {
  section.sns {
    margin-bottom: 50px;
    padding-top: 30px;
    h2 {
      figure {
        .lg.h2_logo(165px, 70px, 8);
      }
    }
    .sns-inner {
      width: 700px;
      .row:nth-of-type(1) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 60px;
        .col {
          width: 48%;
          p.title {
            font-size: 18px;
            margin-bottom: 15px;
            padding-left: 20px;
          }
        }
        .widget-fb {
          width: 100%;
          height: 400px;
          margin: 0 auto;
          border: 1px solid @color-light-gray;
          .fb-page {
            width: 100%;
            height: 100%;
          }
        }
        .widget-tw {
          display: inline-block;
          width: 340px;
          height: 400px;
          margin: 0 auto;
          border: 1px solid @color-light-gray;
          overflow-y: scroll;
          iframe {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .row:nth-of-type(2) {
        width: 100%;
        p.title {
          font-size: 18px;
          margin-bottom: 15px;
          padding-left: 20px;
        }
        .widget-ig {
          width: 100%;
          div.eapps-widget.eapps-widget-show-toolbar:before {
            border: none;
          }
          div.eapps-widget.eapps-widget-show-toolbar:hover:before {
            border: none;
          }
          .eapps-instagram-feed-posts-item:hover.eapps-instagram-feed-posts-item-template-tile .eapps-instagram-feed-posts-item-image {
            transform: scale(1.05) translate(-50%, -50%);
          }
        }
      }
    }
  }
}

@media screen and (max-width: (@responsive-threshold - 1)) {
  section.sns {
    margin-bottom: 50px;
    padding-top: 10px;
    h2 {
      margin-bottom: 15px;
      figure {
        .sm.h2_logo(105px, 45px, 8);
      }
    }
    .sns-inner {
      p.title {
        font-size: 18px;
        margin-bottom: 10px;
        padding-left: 10px;
      }
      .widget-fb {
        width: 100%;
        max-width: 500px;
        height: 400px;
        margin: 0 auto 50px;
        border: 1px solid @color-light-gray;
        iframe {
          width: 100%;
          height: 100%;
        }
      }
      .widget-tw {
        width: 100%;
        height: 400px;
        margin: 0 auto 50px;
        border: 1px solid @color-light-gray;
        overflow-y: scroll;
        iframe {
          width: 100%;
          height: 100%;
        }
      }
      .widget-ig {
        width: 100%;
        margin: 0 auto;
        div.eapps-widget.eapps-widget-show-toolbar:hover:before {
          border: none;
        }
        #eapps-instagram-feed-1 .eapps-instagram-feed-posts-item-overlay {
          display: none;
        }
        .eapps-instagram-feed-posts-item:hover.eapps-instagram-feed-posts-item-template-tile .eapps-instagram-feed-posts-item-image {
          transform: scale(1) translate(-50%, -50%);
          filter: none;
        }
      }
    }
  }
}

@import '../_include/variables';
@import '../_include/decoration';
@import '../_include/tools';

@media screen and (min-width: @responsive-threshold){
  section.top {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    div.inner {
      text-align: center;
      h1 {
        margin: 0 auto;
        figure {
          width: 590px;
          height: 120px;
          background-image: url("@{path-svg}logo.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      p {
        color: white;
        font-size: 24px;
        line-height: 36px;
        .line-height-fix(24px, 36px, 55px, 90px);
      }
      span.enter {
        display: inline-block;
        width: 144px;
        height: 45px;
        cursor: pointer;
        background-image: url("@{path-svg}enter.svg");
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        &:after{
          display: block;
          content: "";
          width: 100%;
          height: 1px;
          background-color: @color-white;
          transform: scale(1);
          transition-duration: 0.1s;
          transform-origin: center;
          transition-timing-function: @ease-out-quad;
          position: absolute;
          bottom: 0;
        }
        &:hover:after{
          transform:  scale(0);
        }
      }
    }
  }
}

@media screen and (max-width: (@responsive-threshold - 1)){
  section.top{
    width: 100vw;
    height: 100vh;
    background-color: @color-base-yellow;
    color: @color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    header{
      width: 100%;
      height: 60px;
      background-color: @color-base-yellow;
      box-sizing: border-box;
      position: fixed;
      top: 0;
      z-index: 15;
      opacity: 0;
      transform: translateY(-60px);
      transition-duration: 0.2s;
      transition-property: transform, opacity;
      transition-timing-function: @ease-linear;
      border-bottom: 2px solid @color-line-blue;
      .header-inner{
        width: 100%;
        height: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        figure{
          width: 170px;
          height: 35px;
          background-image: url("@{path-svg}logo.svg");
          background-repeat: no-repeat;
          background-size: contain;
          position: relative;
          top: 2px;
        }
        > span{
          display: block;
          width: 30px;
          height: 22px;
          span{
            display: block;
            width: 100%;
            height: 2px;
            background-color: @color-white;
            transform: translateY(10px);
            position: relative;
            &:before, &:after{
              display: block;
              content: "";
              width: 100%;
              height: 100%;
              background-color: @color-white;
              transition-duration: 0.1s;
              transition-property: transform;
              transform-origin: center;
              transition-timing-function: @ease-linear;
              position: absolute;
              left: 0;
              top: 0;
            }
            &:before{
              transform: translateY(-10px);
            }
            &:after{
              transform: translateY(10px);
            }
          }
          &.open{
            span{
              background-color: transparent;
              &:before{
                transform: translateY(0) rotate(45deg);
              }
              &:after{
                transform: translateY(0) rotate(-45deg);
              }
            }
          }
        }
      }
      nav.open{
        height: calc(~'100vh - 60px');
      }
      nav{
        width: 100%;
        height: 0;
        overflow: hidden;
        background-color: white;
        box-sizing: border-box;
        padding-left: 30px;
        padding-right: 30px;
        transition-duration: 0.1s;
        transition-property: height;
        transition-timing-function: @ease-linear;
        ul{
          color: @color-black;
          font-size: 16px;
          padding-top: 30px;
          li{
            margin-bottom: 20px;
            padding-left: 10px;
            padding-bottom: 5px;
            border-bottom: 2px solid @color-line-green;
          }
        }
      }
      &.show{
        opacity: 1;
        transform: none;
      }
    }
    div.inner{
      text-align: center;
      position: fixed;
      h1{
        width: calc(100vw - 40px);
        height: calc((100vw - 40px) * (67/338));
        margin-left: auto;
        margin-right: auto;
        figure{
          width: 100%;
          height: 100%;
          background-image: url("@{path-svg}logo.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      p{
        font-size: 14px;
        line-height: 1.5;
        margin-top: 30px;
        margin-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
      }
      span.enter {
        display: inline-block;
        width: 100px;
        height: 30px;
        text-decoration: none;
        background-image: url("@{path-svg}enter.svg");
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        &:after {
          display: block;
          content: "";
          width: 100%;
          height: 1px;
          background-color: @color-white;
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
}

@import '../_include/variables';
@import '../_include/decoration';

@media screen and (min-width: @responsive-threshold){
  section.info{
    margin-bottom: 120px;
    padding-top: 30px;
    h2{
      figure{
        .lg.h2_logo(205px, 70px, 4);
      }
    }
    div.info-inner{
      width: 756px;
      height: 520px;
      margin-left: -30px;
      background-image: url("@{path-pc-img}bg_shopinfo.jpg");
      background-size: 756px 520px;
      background-position: center;
      background-repeat: no-repeat;
      border-top: 6px solid @color-line-blue;
      border-bottom: 6px solid @color-line-blue;
      div.info-data{
        padding: 25px 20px;
        dl{
          margin-bottom: 20px;
          dt{
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 5px;
          }
          dd{
            font-size: 18px;
            font-family: Maru Folk Regular;
            line-height: 1.3;
          }
        }
      }
    }
    div#mapOuter{
      width: 756px;
      height: 400px;
      margin-left: -30px;
      background-color: @color-light-gray;
      border-bottom: 6px solid @color-line-blue;
    }
  }
}

@media screen and (max-width: (@responsive-threshold - 1)){
  section.info{
    margin-bottom: 90px;
    padding-top: 10px;
    h2{
      margin-bottom: 15px;
      figure{
        .sm.h2_logo(150px, 45px, 4);
      }
    }
    .info-inner{
      width: 100vw;
      height: calc(100vw * (518/750));
      margin-left: -20px;
      background-image: url("@{path-sm-img}bg_shopinfo.jpg");
      background-size: cover;
      box-sizing: border-box;
      padding-top: 5px;
      padding-left: 20px;
      border-top: 3px solid @color-line-blue;
      border-bottom: 3px solid @color-line-blue;
      position: relative;
      .info-data{
        dl{
          margin-bottom: 7px;
          letter-spacing: 0.05rem;
          dt{
            font-size: 13px;
            margin-bottom: 3px;
            text-shadow: 0 0 1px #ffffff;
          }
          dd{
            font-size: 12px;
            line-height: 1.2;
            text-shadow: 0 0 1px #ffffff;
            font-family: Maru Folk Regular;
          }
        }
      }
    }
    #mapOuter{
      width: 100vw;
      height: calc(100vw * (518/750));
      background-color: gray;
      margin-left: -20px;
      box-sizing: border-box;
      border-bottom: 3px solid @color-line-blue;
    }
  }
}

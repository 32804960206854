@import '../_include/variables';
@import '../_include/decoration';

@media screen and (min-width: @responsive-threshold) {
  section.menu {
    margin-bottom: 120px;
    padding-top: 30px;
    h2 {
      figure {
        .lg.h2_logo(230px, 70px, 3);
      }
    }
    div.menu-inner {
      & > figure {
        .lg.figure-img("pic_choripan2", 40px, -0.7deg);
      }
      div.blackboard {
        .lg.blackboard();
        text-align: center;
        div.bkboard-top {
          p {
            margin-bottom: 30px;
            font-size: 30px;
            font-family: Maru Folk Bold;
            &:last-child {
              font-size: 16px;
            }
            a {
              color: @color-yellow-menu;
            }
          }
        }
        div.bkboard-bottom {
          p {
            font-size: 16px;
            line-height: 1.2;
            color: @color-white;
            &:first-child {
              .line-height-fix-bottom(16px, 16px*1.2, 20px);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (@responsive-threshold - 1)) {
  section.menu {
    margin-bottom: 90px;
    padding-top: 10px;
    h2 {
      margin-bottom: 15px;
      figure {
        .sm.h2_logo(145px, 45px, 3);
      }
    }
    .menu-inner {
      & > figure {
        .sm.figure-img("pic_choripan2", 30px);
        margin-left: -20px;
      }
      div.blackboard {
        .sm.blackboard();
        text-align: center;
        div.bkboard-top {
          p {
            font-size: 16px;
            margin-bottom: 15px;
            &:last-child {
              font-size: 12px;
            }
          }
          a {
            color: @color-yellow-menu;
          }
        }
        div.bkboard-bottom {
          white-space: nowrap;
          color: @color-white;
          font-size: 12px;
          line-height: (16/12);
          p:first-child {
            .line-height-fix-bottom(12px, (12px+(16/12)), 30);
          }
        }
      }
    }
  }
}

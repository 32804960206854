body {
  font-family: Maru Folk Medium;
  color: #404040;
  background-color: #fbcc16;
}
@media screen and (min-width: 780px) {
  body {
    width: 100%;
  }
  body .document-wrapper {
    padding-top: 100vh;
    overflow: hidden;
  }
  body .document-wrapper span.top-border.fixed {
    position: fixed;
    top: 0;
    left: 0;
  }
  body .document-wrapper span.top-border,
  body .document-wrapper span.bottom-border {
    display: block;
    width: 100vw;
    height: 6px;
    background-color: #2CA6E0;
    position: absolute;
    z-index: 1;
  }
  body .document-wrapper span.bottom-border {
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.7);
  }
  body .document-wrapper main {
    display: block;
    overflow: hidden;
    width: 1024px;
    margin: 0 auto;
    background-color: #fbcc16;
    box-sizing: border-box;
    position: relative;
    color: #404040;
  }
  body .document-wrapper main div.contents {
    width: 756px;
    float: right;
    border-right: 6px solid #00AC89;
    border-left: 6px solid #00AC89;
    background-image: url("../images/lg/bg.png");
    background-repeat: repeat;
    background-size: auto;
    background-position: left top;
  }
  body .document-wrapper main div.contents div.inner-content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px;
  }
  body .document-wrapper main div.contents div.inner-content section h2 {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 779px) {
  body .document-wrapper {
    overflow: hidden;
  }
  body .document-wrapper span.top-border,
  body .document-wrapper span.bottom-border {
    display: block;
    width: 100vw;
    height: 3px;
    background-color: #2CA6E0;
    position: relative;
    z-index: 1;
  }
  body .document-wrapper span.bottom-border {
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.7);
  }
  body .document-wrapper main#main {
    display: block;
    position: relative;
  }
  body .document-wrapper main#main div.contents {
    background-image: url("../images/lg/bg.png");
    background-repeat: repeat;
    background-size: auto;
    background-position: left top;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (min-width: 780px) {
  .sm-only {
    display: none !important;
  }
}
@media screen and (max-width: 779px) {
  .lg-only {
    display: none !important;
  }
}
@media screen and (min-width: 321px) {
  .SE-only {
    display: none !important;
  }
}
@media screen and (min-width: 780px) {
  section.top {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
  }
  section.top div.inner {
    text-align: center;
  }
  section.top div.inner h1 {
    margin: 0 auto;
  }
  section.top div.inner h1 figure {
    width: 590px;
    height: 120px;
    background-image: url("../images/svg/logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.top div.inner p {
    color: white;
    font-size: 24px;
    line-height: 36px;
    margin-top: 49px;
    margin-bottom: 84px;
  }
  section.top div.inner span.enter {
    display: inline-block;
    width: 144px;
    height: 45px;
    cursor: pointer;
    background-image: url("../images/svg/enter.svg");
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
  }
  section.top div.inner span.enter:after {
    display: block;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #ffffff;
    transform: scale(1);
    transition-duration: 0.1s;
    transform-origin: center;
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    position: absolute;
    bottom: 0;
  }
  section.top div.inner span.enter:hover:after {
    transform: scale(0);
  }
}
@media screen and (max-width: 779px) {
  section.top {
    width: 100vw;
    height: 100vh;
    background-color: #fbcc16;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  section.top header {
    width: 100%;
    height: 60px;
    background-color: #fbcc16;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    z-index: 15;
    opacity: 0;
    transform: translateY(-60px);
    transition-duration: 0.2s;
    transition-property: transform, opacity;
    transition-timing-function: linear;
    border-bottom: 2px solid #2CA6E0;
  }
  section.top header .header-inner {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  section.top header .header-inner figure {
    width: 170px;
    height: 35px;
    background-image: url("../images/svg/logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    top: 2px;
  }
  section.top header .header-inner > span {
    display: block;
    width: 30px;
    height: 22px;
  }
  section.top header .header-inner > span span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    transform: translateY(10px);
    position: relative;
  }
  section.top header .header-inner > span span:before,
  section.top header .header-inner > span span:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    transition-duration: 0.1s;
    transition-property: transform;
    transform-origin: center;
    transition-timing-function: linear;
    position: absolute;
    left: 0;
    top: 0;
  }
  section.top header .header-inner > span span:before {
    transform: translateY(-10px);
  }
  section.top header .header-inner > span span:after {
    transform: translateY(10px);
  }
  section.top header .header-inner > span.open span {
    background-color: transparent;
  }
  section.top header .header-inner > span.open span:before {
    transform: translateY(0) rotate(45deg);
  }
  section.top header .header-inner > span.open span:after {
    transform: translateY(0) rotate(-45deg);
  }
  section.top header nav.open {
    height: calc(100vh - 60px);
  }
  section.top header nav {
    width: 100%;
    height: 0;
    overflow: hidden;
    background-color: white;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    transition-duration: 0.1s;
    transition-property: height;
    transition-timing-function: linear;
  }
  section.top header nav ul {
    color: #404040;
    font-size: 16px;
    padding-top: 30px;
  }
  section.top header nav ul li {
    margin-bottom: 20px;
    padding-left: 10px;
    padding-bottom: 5px;
    border-bottom: 2px solid #00AC89;
  }
  section.top header.show {
    opacity: 1;
    transform: none;
  }
  section.top div.inner {
    text-align: center;
    position: fixed;
  }
  section.top div.inner h1 {
    width: calc(100vw - 40px);
    height: calc((100vw - 40px) * (67/338));
    margin-left: auto;
    margin-right: auto;
  }
  section.top div.inner h1 figure {
    width: 100%;
    height: 100%;
    background-image: url("../images/svg/logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.top div.inner p {
    font-size: 14px;
    line-height: 1.5;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
  }
  section.top div.inner span.enter {
    display: inline-block;
    width: 100px;
    height: 30px;
    text-decoration: none;
    background-image: url("../images/svg/enter.svg");
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
  }
  section.top div.inner span.enter:after {
    display: block;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #ffffff;
    position: absolute;
    bottom: 0;
  }
}
@media screen and (min-width: 780px) {
  section.nav.fixed {
    position: fixed;
    top: 0;
    left: auto;
  }
  section.nav {
    width: 256px;
    box-sizing: border-box;
    padding-top: 35px;
    float: left;
  }
  section.nav div.inner-nav {
    width: 100%;
  }
  section.nav div.inner-nav figure.logo {
    display: block;
    width: 244px;
    height: 44px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 35px;
    background-image: url("../images/svg/logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }
  section.nav div.inner-nav nav {
    margin-bottom: 40px;
  }
  section.nav div.inner-nav nav ul li {
    margin-left: 15px;
    margin-bottom: 15px;
    position: relative;
    cursor: pointer;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  section.nav div.inner-nav nav ul li span {
    position: relative;
    z-index: 1;
    color: #ffffff;
    font-size: 18px;
    left: 8px;
    font-family: Maru Folk Bold;
    bottom: 1px;
  }
  section.nav div.inner-nav nav ul li:after {
    display: block;
    content: "";
    background-color: #00AC89;
    border-radius: 7px 0 0 7px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transition-duration: 0.5s;
    transform-origin: right;
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    transition-property: transform;
  }
  section.nav div.inner-nav nav ul li:hover:after {
    transform: none;
  }
  section.nav div.inner-nav nav ul li.active:after {
    transform: none;
  }
  section.nav div.inner-nav div.sns {
    margin-left: 20px;
    overflow: hidden;
  }
  section.nav div.inner-nav div.sns div {
    float: left;
    width: 80px;
    margin-right: 10px;
  }
  section.nav div.inner-nav div.sns div.hatena {
    margin-top: 5px;
  }
}
@media screen and (max-width: 779px) {
  section.nav {
    display: none;
  }
}
@media screen and (min-width: 780px) {
  section.about {
    margin-bottom: 100px;
    padding-top: 30px;
  }
  section.about h2 figure {
    width: 400px;
    height: 70px;
    background-image: url("../images/svg/title1.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.about div.container {
    margin-bottom: 100px;
  }
  section.about div.container > p {
    letter-spacing: 0.03rem;
    line-height: 1.5;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  section.about div.container p {
    font-size: 16px;
    line-height: 2;
    margin-top: -8px;
    margin-bottom: 37px;
  }
  section.about div.container h3 {
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
    font-family: Maru Folk Bold;
  }
  section.about div.container h3 span {
    font-size: 30px;
    display: inline-block;
    position: relative;
  }
  section.about div.container h3 span:before,
  section.about div.container h3 span:after {
    display: block;
    content: "";
    width: 45px;
    height: 10px;
    background-image: url("../images/svg/mark.svg");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 10px;
  }
  section.about div.container h3 span:before {
    left: -50px;
  }
  section.about div.container h3 span:after {
    right: -53px;
  }
  section.about div.container:nth-of-type(1) figure {
    width: 700px;
    height: 390px;
    background-color: #d3d3d3;
    background-image: url("../images/lg/pic_choripan3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
    border: 6px solid #ffffff;
    border-radius: 3px;
    box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.6);
    transform: rotate(-0.7deg);
  }
  section.about div.container:nth-of-type(2) figure {
    width: 700px;
    height: 390px;
    background-color: #d3d3d3;
    background-image: url("../images/lg/pic_choriso1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
    border: 6px solid #ffffff;
    border-radius: 3px;
    box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.6);
    transform: rotate(1.2deg);
  }
  section.about div.container:nth-of-type(3) figure {
    width: 700px;
    height: 390px;
    background-color: #d3d3d3;
    background-image: url("../images/lg/pic_chimichurri1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
    border: 6px solid #ffffff;
    border-radius: 3px;
    box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.6);
    transform: rotate(-0.7deg);
  }
  section.about div.container:nth-of-type(4) figure {
    width: 700px;
    height: 390px;
    background-color: #d3d3d3;
    background-image: url("../images/lg/pic_topping1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
    border: 6px solid #ffffff;
    border-radius: 3px;
    box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.6);
    transform: rotate(-0.7deg);
  }
  section.about div.slide h3 {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    font-family: Maru Folk Bold;
  }
  section.about div.slide h3 span {
    font-size: 30px;
    display: inline-block;
    position: relative;
  }
  section.about div.slide h3 span:before,
  section.about div.slide h3 span:after {
    display: block;
    content: "";
    width: 45px;
    height: 10px;
    background-image: url("../images/svg/mark.svg");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 10px;
  }
  section.about div.slide h3 span:before {
    left: -50px;
  }
  section.about div.slide h3 span:after {
    right: -53px;
  }
  section.about div.slide div.carousel-wrapper {
    position: relative;
    margin-left: -30px;
    width: 756px;
    height: 400px;
    background-color: #d3d3d3;
    border-top: 6px solid #2CA6E0;
    border-bottom: 6px solid #2CA6E0;
  }
  section.about div.slide div.carousel-wrapper figure.change-btn {
    display: block;
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    border-radius: 3px;
    opacity: 0.6;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 99;
  }
  section.about div.slide div.carousel-wrapper figure.change-btn.prev-btn {
    left: 20px;
  }
  section.about div.slide div.carousel-wrapper figure.change-btn.prev-btn.hide {
    display: none;
  }
  section.about div.slide div.carousel-wrapper figure.change-btn.next-btn {
    right: 20px;
  }
  section.about div.slide div.carousel-wrapper figure.change-btn.next-btn.hide {
    display: none;
  }
  section.about div.slide div.carousel-wrapper figure.change-btn span {
    font-size: 36px;
    position: relative;
    top: 4px;
  }
  section.about div.slide div.carousel-wrapper ul {
    position: relative;
    width: 756px;
    height: 400px;
    overflow: hidden;
  }
  section.about div.slide div.carousel-wrapper ul li {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
  section.about div.slide div.carousel-wrapper ul li.active {
    animation-name: active-move;
  }
  section.about div.slide div.carousel-wrapper ul li.next-move {
    animation-name: next-move;
  }
  section.about div.slide div.carousel-wrapper ul li.prev-move {
    animation-name: prev-move;
  }
  section.about div.slide div.carousel-wrapper ul li img {
    display: block;
    width: 100%;
    height: 100%;
  }
  section.about div.slide div.carousel-wrapper ul p {
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    line-height: 2;
    padding-top: 5px;
    padding-bottom: 5px;
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }
}
@media screen and (max-width: 779px) {
  section.about {
    margin-bottom: 50px;
  }
  section.about h2 {
    padding-top: 20px;
    margin-bottom: 15px;
  }
  section.about h2 figure {
    width: 240px;
    height: 45px;
    background-image: url("../images/svg/title1.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.about div.container {
    margin-bottom: 70px;
  }
  section.about div.container p {
    line-height: 1.6;
    margin-top: -8px;
    margin-bottom: 22px;
    font-size: 15px;
  }
  section.about div.container p:last-child {
    margin-bottom: 0;
  }
  section.about div.container h3 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
    font-family: Maru Folk Bold;
  }
  section.about div.container h3 span {
    display: inline-block;
    position: relative;
  }
  section.about div.container h3 span:before,
  section.about div.container h3 span:after {
    display: block;
    content: "";
    width: 32px;
    height: 8px;
    background-image: url("../images/svg/mark.svg");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 6px;
  }
  section.about div.container h3 span:before {
    left: -35px;
  }
  section.about div.container h3 span:after {
    right: -35px;
  }
  section.about div.container:last-child {
    margin-bottom: 0;
  }
  section.about div.container figure {
    margin-left: -20px;
  }
  section.about div.container:nth-of-type(1) figure {
    width: 100vw;
    height: calc(100vw * 414/750);
    background-color: #d3d3d3;
    background-image: url("../images/sm/pic_choripan3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
  }
  section.about div.container:nth-of-type(2) figure {
    width: 100vw;
    height: calc(100vw * 414/750);
    background-color: #d3d3d3;
    background-image: url("../images/sm/pic_choriso1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
  }
  section.about div.container:nth-of-type(3) figure {
    width: 100vw;
    height: calc(100vw * 414/750);
    background-color: #d3d3d3;
    background-image: url("../images/sm/pic_chimichurri1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
  }
  section.about div.container:nth-of-type(4) figure {
    width: 100vw;
    height: calc(100vw * 414/750);
    background-color: #d3d3d3;
    background-image: url("../images/sm/pic_topping1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
  }
  section.about div.slide {
    width: 100vw;
    margin-left: -20px;
    margin-bottom: 90px;
    position: relative;
  }
  section.about div.slide h3 {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    font-family: Maru Folk Bold;
  }
  section.about div.slide h3 span {
    font-size: 16px;
    display: inline-block;
    position: relative;
  }
  section.about div.slide h3 span:before,
  section.about div.slide h3 span:after {
    display: block;
    content: "";
    width: 32px;
    height: 8px;
    background-image: url("../images/svg/mark.svg");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 5px;
  }
  section.about div.slide h3 span:before {
    left: -35px;
  }
  section.about div.slide h3 span:after {
    right: -35px;
  }
  section.about div.slide figure.change-btn {
    display: block;
    width: 30px;
    height: 30px;
    background-color: #ffffff;
    border-radius: 3px;
    opacity: 0.6;
    position: absolute;
    top: 50%;
    z-index: 10;
    text-align: center;
  }
  section.about div.slide figure.change-btn span {
    line-height: 30px;
    font-size: 20px;
  }
  section.about div.slide figure.change-btn.prev-btn {
    left: 20px;
  }
  section.about div.slide figure.change-btn.next-btn {
    right: 20px;
  }
  section.about div.slide div.carousel-wrapper {
    border-top: 3px solid #2CA6E0;
    border-bottom: 3px solid #2CA6E0;
  }
  section.about div.slide div.carousel-wrapper ul {
    width: 100vw;
    height: calc(100vw * 207/375);
    position: relative;
  }
  section.about div.slide div.carousel-wrapper ul li {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  section.about div.slide div.carousel-wrapper ul li img {
    display: block;
    width: 100%;
    height: 100%;
  }
  section.about div.slide div.carousel-wrapper ul li p {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    bottom: 0;
    font-size: 12px;
    line-height: 1.5;
  }
}
@media screen and (min-width: 780px) {
  section.memory {
    margin-bottom: 120px;
    padding-top: 30px;
  }
  section.memory h2 figure {
    width: 523px;
    height: 70px;
    background-image: url("../images/svg/title2.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.memory div.memory-inner figure {
    width: 718px;
    height: 543px;
    background-image: url("../images/lg/memories.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 40px;
  }
  section.memory div.memory-inner p {
    font-size: 16px;
    line-height: 2;
    margin-top: -8px;
    margin-bottom: -8px;
  }
}
@media screen and (max-width: 779px) {
  section.memory {
    margin-bottom: 90px;
    padding-top: 10px;
  }
  section.memory h2 {
    margin-bottom: 15px;
  }
  section.memory h2 figure {
    width: 290px;
    height: 40px;
    background-image: url("../images/svg/title2.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.memory div.memory-inner figure {
    width: 100vw;
    height: calc(100vw * 543/718);
    margin-left: -20px;
    background-image: url("../images/lg/memories.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 30px;
  }
  section.memory div.memory-inner p {
    line-height: 1.6;
    margin-top: -8px;
    margin-bottom: -8px;
  }
}
@media screen and (min-width: 780px) {
  section.menu {
    margin-bottom: 120px;
    padding-top: 30px;
  }
  section.menu h2 figure {
    width: 230px;
    height: 70px;
    background-image: url("../images/svg/title3.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.menu div.menu-inner > figure {
    width: 700px;
    height: 390px;
    background-color: #d3d3d3;
    background-image: url("../images/lg/pic_choripan2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 40px;
    border: 6px solid #ffffff;
    border-radius: 3px;
    box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.6);
    transform: rotate(-0.7deg);
  }
  section.menu div.menu-inner div.blackboard {
    width: 600px;
    background-color: #373F43;
    margin: 0 auto;
    border-radius: 3px;
    box-shadow: 3px -1px 0 1px rgba(0, 0, 0, 0.5);
    text-align: center;
  }
  section.menu div.menu-inner div.blackboard figure.line {
    width: 446px;
    height: 6px;
    background-image: url("../images/lg/line.png");
    background-size: 446px 6px;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 50px 0;
  }
  section.menu div.menu-inner div.blackboard div.bkboard-top p {
    margin-bottom: 30px;
    font-size: 30px;
    font-family: Maru Folk Bold;
  }
  section.menu div.menu-inner div.blackboard div.bkboard-top p:last-child {
    font-size: 16px;
  }
  section.menu div.menu-inner div.blackboard div.bkboard-top p a {
    color: #DADB72;
  }
  section.menu div.menu-inner div.blackboard div.bkboard-bottom p {
    font-size: 16px;
    line-height: 1.2;
    color: #ffffff;
  }
  section.menu div.menu-inner div.blackboard div.bkboard-bottom p:first-child {
    margin-bottom: 18px;
  }
}
@media screen and (max-width: 779px) {
  section.menu {
    margin-bottom: 90px;
    padding-top: 10px;
  }
  section.menu h2 {
    margin-bottom: 15px;
  }
  section.menu h2 figure {
    width: 145px;
    height: 45px;
    background-image: url("../images/svg/title3.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.menu .menu-inner > figure {
    width: 100vw;
    height: calc(100vw * 414/750);
    background-color: #d3d3d3;
    background-image: url("../images/sm/pic_choripan2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 30px;
    margin-left: -20px;
  }
  section.menu .menu-inner div.blackboard {
    width: 100%;
    background-color: #373F43;
    padding: 0 25px;
    box-sizing: border-box;
    text-align: center;
  }
  section.menu .menu-inner div.blackboard figure.line {
    width: 100%;
    height: 6px;
    background-image: url("../images/lg/line.png");
    background-size: auto 6px;
    background-repeat: repeat-x;
    background-position: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  section.menu .menu-inner div.blackboard div.bkboard-top p {
    font-size: 16px;
    margin-bottom: 15px;
  }
  section.menu .menu-inner div.blackboard div.bkboard-top p:last-child {
    font-size: 12px;
  }
  section.menu .menu-inner div.blackboard div.bkboard-top a {
    color: #DADB72;
  }
  section.menu .menu-inner div.blackboard div.bkboard-bottom {
    white-space: nowrap;
    color: #ffffff;
    font-size: 12px;
    line-height: 1.33333333;
  }
  section.menu .menu-inner div.blackboard div.bkboard-bottom p:first-child {
    margin-bottom: 29px;
  }
}
@media screen and (min-width: 780px) {
  section.info {
    margin-bottom: 120px;
    padding-top: 30px;
  }
  section.info h2 figure {
    width: 205px;
    height: 70px;
    background-image: url("../images/svg/title4.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.info div.info-inner {
    width: 756px;
    height: 520px;
    margin-left: -30px;
    background-image: url("../images/lg/bg_shopinfo.jpg");
    background-size: 756px 520px;
    background-position: center;
    background-repeat: no-repeat;
    border-top: 6px solid #2CA6E0;
    border-bottom: 6px solid #2CA6E0;
  }
  section.info div.info-inner div.info-data {
    padding: 25px 20px;
  }
  section.info div.info-inner div.info-data dl {
    margin-bottom: 20px;
  }
  section.info div.info-inner div.info-data dl dt {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  section.info div.info-inner div.info-data dl dd {
    font-size: 18px;
    font-family: Maru Folk Regular;
    line-height: 1.3;
  }
  section.info div#mapOuter {
    width: 756px;
    height: 400px;
    margin-left: -30px;
    background-color: #d3d3d3;
    border-bottom: 6px solid #2CA6E0;
  }
}
@media screen and (max-width: 779px) {
  section.info {
    margin-bottom: 90px;
    padding-top: 10px;
  }
  section.info h2 {
    margin-bottom: 15px;
  }
  section.info h2 figure {
    width: 150px;
    height: 45px;
    background-image: url("../images/svg/title4.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.info .info-inner {
    width: 100vw;
    height: calc(100vw * (518/750));
    margin-left: -20px;
    background-image: url("../images/sm/bg_shopinfo.jpg");
    background-size: cover;
    box-sizing: border-box;
    padding-top: 5px;
    padding-left: 20px;
    border-top: 3px solid #2CA6E0;
    border-bottom: 3px solid #2CA6E0;
    position: relative;
  }
  section.info .info-inner .info-data dl {
    margin-bottom: 7px;
    letter-spacing: 0.05rem;
  }
  section.info .info-inner .info-data dl dt {
    font-size: 13px;
    margin-bottom: 3px;
    text-shadow: 0 0 1px #ffffff;
  }
  section.info .info-inner .info-data dl dd {
    font-size: 12px;
    line-height: 1.2;
    text-shadow: 0 0 1px #ffffff;
    font-family: Maru Folk Regular;
  }
  section.info #mapOuter {
    width: 100vw;
    height: calc(100vw * (518/750));
    background-color: gray;
    margin-left: -20px;
    box-sizing: border-box;
    border-bottom: 3px solid #2CA6E0;
  }
}
@media screen and (min-width: 780px) {
  section.media {
    margin-bottom: 120px;
    padding-top: 30px;
  }
  section.media h2 figure {
    width: 370px;
    height: 70px;
    background-image: url("../images/svg/title5.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.media h2 + figure {
    width: 700px;
    height: 390px;
    background-color: #d3d3d3;
    background-image: url("../images/lg/pic_media.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
    border: 6px solid #ffffff;
    border-radius: 3px;
    box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.6);
    transform: rotate(1.2deg);
  }
  section.media div.media-list ul {
    margin-top: 40px;
  }
  section.media div.media-list ul li {
    font-size: 18px;
    position: relative;
    padding-left: 18px;
    margin-bottom: 15px;
  }
  section.media div.media-list ul li:before {
    display: block;
    content: "";
    width: 8px;
    height: 8px;
    border: 2px solid #ff0000;
    position: absolute;
    border-radius: 50%;
    top: 4px;
    left: 0;
  }
  section.media div.media-list ul li a {
    color: #404040;
  }
  section.media div.media-list ul div.rest-wrapper {
    overflow: hidden;
    transition-duration: 0.2s;
    transition-property: height;
  }
  section.media div.media-list span.btn-more {
    position: relative;
    top: -5px;
    border-bottom: 1px solid #404040;
    font-size: 14px;
    margin-left: 20px;
    padding: 0;
    cursor: pointer;
  }
}
@media screen and (max-width: 779px) {
  section.media {
    margin-bottom: 90px;
    padding-top: 10px;
  }
  section.media h2 {
    margin-bottom: 15px;
  }
  section.media h2 figure {
    width: 230px;
    height: 45px;
    background-image: url("../images/svg/title5.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.media h2 + figure {
    width: 100vw;
    height: calc(100vw * 414/750);
    background-color: #d3d3d3;
    background-image: url("../images/sm/pic_media.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-left: -20px;
    margin-bottom: 30px;
  }
  section.media div.media-list ul {
    margin-top: 30px;
  }
  section.media div.media-list ul li {
    font-size: 13px;
    line-height: 1.5;
    position: relative;
    margin-bottom: 5px;
    font-family: Maru Folk Regular;
    padding-left: 15px;
  }
  section.media div.media-list ul li a {
    color: #404040;
  }
  section.media div.media-list ul li:before {
    display: block;
    content: "";
    width: 3px;
    height: 3px;
    border: 2px solid #ff0000;
    position: absolute;
    border-radius: 50%;
    top: 8px;
    left: 0;
  }
  section.media div.media-list ul div.rest-wrapper {
    overflow: hidden;
    transition-duration: 0.2s;
    transition-property: height;
  }
  section.media div.media-list span.btn-more {
    font-family: Maru Folk Regular;
    border-bottom: 1px solid #404040;
    font-size: 12px;
    margin-left: 20px;
  }
}
@media screen and (min-width: 780px) {
  section.news {
    margin-bottom: 120px;
    padding-top: 30px;
  }
  section.news h2 figure {
    width: 240px;
    height: 70px;
    background-image: url("../images/svg/title6.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.news div.news-inner div.blackboard {
    width: 600px;
    background-color: #373F43;
    margin: 0 auto;
    border-radius: 3px;
    box-shadow: 3px -1px 0 1px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    padding-left: 80px;
    padding-right: 80px;
    color: #ffffff;
  }
  section.news div.news-inner div.blackboard figure.line {
    width: 446px;
    height: 6px;
    background-image: url("../images/lg/line.png");
    background-size: 446px 6px;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 50px 0;
  }
  section.news div.news-inner div.blackboard p {
    line-height: 2;
    font-size: 16px;
    color: #ffffff;
  }
  section.news div.news-inner div.blackboard p span {
    color: #ff0000;
  }
  section.news div.news-inner div.blackboard p.title1,
  section.news div.news-inner div.blackboard p.title2 {
    text-align: center;
  }
  section.news div.news-inner div.blackboard p.title1.title2,
  section.news div.news-inner div.blackboard p.title2.title2 {
    margin: 0;
  }
}
@media screen and (max-width: 779px) {
  section.news {
    margin-bottom: 90px;
    padding-top: 10px;
  }
  section.news h2 {
    margin-bottom: 15px;
  }
  section.news h2 figure {
    width: 135px;
    height: 40px;
    background-image: url("../images/svg/title6.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.news .news-inner div.blackboard {
    width: 100%;
    background-color: #373F43;
    padding: 0 25px;
    box-sizing: border-box;
  }
  section.news .news-inner div.blackboard figure.line {
    width: 100%;
    height: 6px;
    background-image: url("../images/lg/line.png");
    background-size: auto 6px;
    background-repeat: repeat-x;
    background-position: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  section.news .news-inner div.blackboard p {
    line-height: 1.6;
    margin-top: -8px;
    margin-bottom: -8px;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    color: #ffffff;
  }
}
@media screen and (min-width: 780px) {
  section.recruit {
    margin-bottom: 120px;
    padding-top: 30px;
  }
  section.recruit h2 figure {
    width: 180px;
    height: 70px;
    background-image: url("../images/svg/title9.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.recruit div.recruit-inner .blackboard {
    width: 600px;
    background-color: #373F43;
    margin: 0 auto;
    border-radius: 3px;
    box-shadow: 3px -1px 0 1px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    padding-left: 80px;
    padding-right: 80px;
  }
  section.recruit div.recruit-inner .blackboard figure.line {
    width: 446px;
    height: 6px;
    background-image: url("../images/lg/line.png");
    background-size: 446px 6px;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 50px 0;
  }
  section.recruit div.recruit-inner p {
    line-height: 2;
    font-size: 16px;
    color: #ffffff;
  }
}
@media screen and (max-width: 779px) {
  section.recruit {
    margin-bottom: 90px;
    padding-top: 10px;
  }
  section.recruit h2 {
    margin-bottom: 15px;
  }
  section.recruit h2 figure {
    width: 105px;
    height: 45px;
    background-image: url("../images/svg/title9.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.recruit div.recruit-inner .blackboard {
    width: 100%;
    background-color: #373F43;
    padding: 0 25px;
    box-sizing: border-box;
  }
  section.recruit div.recruit-inner .blackboard figure.line {
    width: 100%;
    height: 6px;
    background-image: url("../images/lg/line.png");
    background-size: auto 6px;
    background-repeat: repeat-x;
    background-position: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  section.recruit div.recruit-inner .blackboard p {
    line-height: 1.6;
    margin-top: -8px;
    margin-bottom: -8px;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    color: #ffffff;
  }
}
@media screen and (min-width: 780px) {
  section.photo {
    margin-bottom: 120px;
    padding-top: 30px;
  }
  section.photo h2 figure {
    width: 375px;
    height: 70px;
    background-image: url("../images/svg/title7.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.photo .photo-inner .photogallery {
    font-size: 0;
    width: 630px;
    margin-left: auto;
    margin-right: auto;
  }
  section.photo .photo-inner .photogallery a {
    display: inline-block;
    width: 180px;
    height: 120px;
    padding: 8px;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    border-radius: 3px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  section.photo .photo-inner .photogallery a img {
    width: 100%;
    height: 100%;
  }
  section.photo .photo-inner .photogallery a:nth-child(3n-1) {
    margin-right: 20px;
    margin-left: 20px;
  }
  section.photo .photo-inner .photogallery a:nth-of-type(even):hover {
    transform: rotate(-1deg);
  }
  section.photo .photo-inner .photogallery a:nth-of-type(odd):hover {
    transform: rotate(1deg);
  }
}
@media screen and (max-width: 779px) {
  section.photo {
    margin-bottom: 70px;
    padding-top: 10px;
  }
  section.photo h2 {
    margin-bottom: 15px;
  }
  section.photo h2 figure {
    width: 240px;
    height: 45px;
    background-image: url("../images/svg/title7.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.photo .photo-inner .photogallery {
    font-size: 0;
  }
  section.photo .photo-inner .photogallery a {
    display: inline-block;
    width: calc((100vw - 40px - 20px) / 3);
    height: calc(((100vw - 40px - 20px) / 3) * (69/104));
    margin-bottom: 23px;
  }
  section.photo .photo-inner .photogallery a img {
    width: 100%;
    height: 100%;
  }
  section.photo .photo-inner .photogallery a:nth-child(3n-1) {
    margin-right: 10px;
    margin-left: 10px;
  }
}
@media screen and (min-width: 780px) {
  section.sns {
    margin-bottom: 50px;
    padding-top: 30px;
  }
  section.sns h2 figure {
    width: 165px;
    height: 70px;
    background-image: url("../images/svg/title8.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.sns .sns-inner {
    width: 700px;
  }
  section.sns .sns-inner .row:nth-of-type(1) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 60px;
  }
  section.sns .sns-inner .row:nth-of-type(1) .col {
    width: 48%;
  }
  section.sns .sns-inner .row:nth-of-type(1) .col p.title {
    font-size: 18px;
    margin-bottom: 15px;
    padding-left: 20px;
  }
  section.sns .sns-inner .row:nth-of-type(1) .widget-fb {
    width: 100%;
    height: 400px;
    margin: 0 auto;
    border: 1px solid #d3d3d3;
  }
  section.sns .sns-inner .row:nth-of-type(1) .widget-fb .fb-page {
    width: 100%;
    height: 100%;
  }
  section.sns .sns-inner .row:nth-of-type(1) .widget-tw {
    display: inline-block;
    width: 340px;
    height: 400px;
    margin: 0 auto;
    border: 1px solid #d3d3d3;
    overflow-y: scroll;
  }
  section.sns .sns-inner .row:nth-of-type(1) .widget-tw iframe {
    width: 100% !important;
    height: 100% !important;
  }
  section.sns .sns-inner .row:nth-of-type(2) {
    width: 100%;
  }
  section.sns .sns-inner .row:nth-of-type(2) p.title {
    font-size: 18px;
    margin-bottom: 15px;
    padding-left: 20px;
  }
  section.sns .sns-inner .row:nth-of-type(2) .widget-ig {
    width: 100%;
  }
  section.sns .sns-inner .row:nth-of-type(2) .widget-ig div.eapps-widget.eapps-widget-show-toolbar:before {
    border: none;
  }
  section.sns .sns-inner .row:nth-of-type(2) .widget-ig div.eapps-widget.eapps-widget-show-toolbar:hover:before {
    border: none;
  }
  section.sns .sns-inner .row:nth-of-type(2) .widget-ig .eapps-instagram-feed-posts-item:hover.eapps-instagram-feed-posts-item-template-tile .eapps-instagram-feed-posts-item-image {
    transform: scale(1.05) translate(-50%, -50%);
  }
}
@media screen and (max-width: 779px) {
  section.sns {
    margin-bottom: 50px;
    padding-top: 10px;
  }
  section.sns h2 {
    margin-bottom: 15px;
  }
  section.sns h2 figure {
    width: 105px;
    height: 45px;
    background-image: url("../images/svg/title8.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  section.sns .sns-inner p.title {
    font-size: 18px;
    margin-bottom: 10px;
    padding-left: 10px;
  }
  section.sns .sns-inner .widget-fb {
    width: 100%;
    max-width: 500px;
    height: 400px;
    margin: 0 auto 50px;
    border: 1px solid #d3d3d3;
  }
  section.sns .sns-inner .widget-fb iframe {
    width: 100%;
    height: 100%;
  }
  section.sns .sns-inner .widget-tw {
    width: 100%;
    height: 400px;
    margin: 0 auto 50px;
    border: 1px solid #d3d3d3;
    overflow-y: scroll;
  }
  section.sns .sns-inner .widget-tw iframe {
    width: 100%;
    height: 100%;
  }
  section.sns .sns-inner .widget-ig {
    width: 100%;
    margin: 0 auto;
  }
  section.sns .sns-inner .widget-ig div.eapps-widget.eapps-widget-show-toolbar:hover:before {
    border: none;
  }
  section.sns .sns-inner .widget-ig #eapps-instagram-feed-1 .eapps-instagram-feed-posts-item-overlay {
    display: none;
  }
  section.sns .sns-inner .widget-ig .eapps-instagram-feed-posts-item:hover.eapps-instagram-feed-posts-item-template-tile .eapps-instagram-feed-posts-item-image {
    transform: scale(1) translate(-50%, -50%);
    filter: none;
  }
}
@media screen and (min-width: 780px) {
  footer {
    width: 756px;
    height: 50px;
    line-height: 50px;
    color: #ffffff;
    font-size: 16px;
    background-color: #00AC89;
    text-align: center;
    margin-left: -30px;
  }
  footer a {
    color: #ffffff;
  }
}
@media screen and (max-width: 779px) {
  footer {
    width: 100vw;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.4;
    color: #ffffff;
    font-size: 12px;
    background-color: #00AC89;
    text-align: center;
    margin-left: -20px;
  }
  footer a {
    color: #ffffff;
  }
}

@import '../_include/variables';
@import '../_include/decoration';

@media screen and (min-width: @responsive-threshold) {
  section.news {
    margin-bottom: 120px;
    padding-top: 30px;
    h2 {
      figure {
        .lg.h2_logo(240px, 70px, 6);
      }
    }
    div.news-inner {
      div.blackboard {
        .lg.blackboard();
        box-sizing: border-box;
        padding-left: 80px;
        padding-right: 80px;
        color: @color-white;
        p {
          line-height: 2;
          font-size: 16px;
          color: @color-white;
          span {
            color: @color-red;
          }
          &.title1, &.title2 {
            text-align: center;
            &.title2 {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (@responsive-threshold - 1)) {
  section.news {
    margin-bottom: 90px;
    padding-top: 10px;
    h2 {
      margin-bottom: 15px;
      figure {
        .sm.h2_logo(135px, 40px, 6);
      }
    }
    .news-inner {
      div.blackboard {
        .sm.blackboard();
        p {
          .sm.text-style();
          font-size: 13px;
          margin-top: 10px;
          margin-bottom: 10px;
          padding-left: 5px;
          padding-right: 5px;
          color: @color-white;
        }
      }
    }
  }
}

@import '../_include/variables';
@import '../_include/decoration';

@media screen and (min-width: @responsive-threshold){
  section.about{
    margin-bottom: 100px;
    padding-top: 30px;
    h2{
      figure{
      .lg.h2_logo(400px, 70px, 1);
      }
    }
    div.container{
      & > p{
        letter-spacing: 0.03rem;
        line-height: 1.5;
        margin-top: 30px;
        margin-bottom: 30px;
      }
    .lg.about-style();
      &:nth-of-type(1) figure{ .lg.figure-img("pic_choripan3",20px,-0.7deg);}
      &:nth-of-type(2) figure{ .lg.figure-img("pic_choriso1",20px,1.2deg);}
      &:nth-of-type(3) figure{ .lg.figure-img("pic_chimichurri1",20px,-0.7deg);}
      &:nth-of-type(4) figure{ .lg.figure-img("pic_topping1",20px,-0.7deg);}
    }
    div.slide{
      h3{
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        font-family: Maru Folk Bold;
        span{
          font-size: 30px;
          display: inline-block;
          position: relative;
          &:before, &:after{
            display: block;
            content: "";
            width: 45px;
            height: 10px;
            background-image: url("@{path-svg}mark.svg");
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top: 10px;
          }
          &:before{
            left: -50px;
          }
          &:after{
            right: -53px;
          }
        }
      }
      div.carousel-wrapper {
        position: relative;
        figure.change-btn{
          display: block;
          width: 50px;
          height: 50px;
          background-color: @color-white;
          border-radius: 3px;
          opacity: 0.6;
          text-align: center;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          z-index: 99;
          &.prev-btn{
            left: 20px;
            &.hide {
              display: none;
            }
          }
          &.next-btn{
            right: 20px;
            &.hide {
              display: none;
            }
          }
          span{
            font-size: 36px;
            position: relative;
            top: 4px;
          }
        }
        margin-left: -30px;
        width: 756px;
        height: 400px;
        background-color: @color-light-gray;
        border-top: 6px solid @color-line-blue;
        border-bottom: 6px solid @color-line-blue;
        ul{
          position: relative;
          width: 756px;
          height: 400px;
          overflow: hidden;
          li{
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            &.active{
              animation-name: active-move;
            }
            &.next-move{
              animation-name: next-move;
            }
            &.prev-move{
              animation-name: prev-move;
            }
            img{
              display: block;
              width: 100%;
              height: 100%;
            }
          }
          p{
            width: 100%;
            text-align: center;
            color: @color-white;
            font-size: 16px;
            line-height: 2;
            padding-top: 5px;
            padding-bottom: 5px;
            position: absolute;
            bottom: 0;
            background-color: fadeout(@color-black-pure, 30%);
          }
        }
      }
    }
  }
}

@media screen and (max-width: (@responsive-threshold - 1)){
  section.about{
    margin-bottom: 50px;
    h2{
      padding-top: 20px;
      margin-bottom: 15px;
      figure{
      .sm.h2_logo(240px, 45px, 1);
      }
    }
    div.container{
    .sm.about-section-style();
        &:last-child{
          margin-bottom: 0;
        }
        figure{
          margin-left: -20px;
        }
        &:nth-of-type(1) figure{
        .sm.figure-img("pic_choripan3", 20px);
        }
        &:nth-of-type(2) figure{
        .sm.figure-img("pic_choriso1", 20px);
        }
        &:nth-of-type(3) figure{
        .sm.figure-img("pic_chimichurri1", 20px);
        }
        &:nth-of-type(4) figure{
        .sm.figure-img("pic_topping1", 20px);
        }
      }
    div.slide{
      width: 100vw;
      margin-left: -20px;
      margin-bottom: 90px;
      position: relative;
      h3{
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
        font-family: Maru Folk Bold;
        span{
          font-size: 16px;
          display: inline-block;
          position: relative;
          &:before, &:after{
            display: block;
            content: "";
            width: 32px;
            height: 8px;
            background-image: url("@{path-svg}mark.svg");
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top: 5px;
          }
          &:before{
            left: -35px;
          }
          &:after{
            right: -35px;
          }
        }
      }
      figure.change-btn{
        display: block;
        width: 30px;
        height: 30px;
        background-color: @color-white;
        border-radius: 3px;
        opacity: 0.6;
        position: absolute;
        top: 50%;
        z-index: 10;
        text-align: center;
        span{
          line-height: 30px;
          font-size: 20px;
        }
        &.prev-btn{
          left: 20px;
        }
        &.next-btn{
          right: 20px;
        }
      }
      div.carousel-wrapper{
        border-top: 3px solid @color-line-blue;
        border-bottom: 3px solid @color-line-blue;
        ul{
          width: 100vw;
          height: calc(100vw * 207/375);
          position: relative;
          li{
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            img{
              display: block;
              width: 100%;
              height: 100%;
            }
            p{
              width: 100%;
              padding-top: 10px;
              padding-bottom: 10px;
              text-align: center;
              color: white;
              background-color: rgba(0, 0, 0, 0.7);
              position: absolute;
              bottom: 0;
              font-size: 12px;
              line-height: 1.5;
            }
          }
        }
      }
    }
  }
}

@import (reference) "../variables";
@import (reference) "../tools";
@import (reference) "../relatives";

.sm{
  .h2_logo(@width,@height,@num) {
    width: @width;
    height: @height;
    background-image: url("@{path-svg}title@{num}.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  .text-style(@bottom:0){
  line-height: 1.6;
  .line-height-fix-top(16px, 16px*2);
  .line-height-fix-bottom(16px, 16px*2, @bottom);
}
  .about-section-style(){
  margin-bottom: 70px;
  p{
  .sm.text-style(30px);
  font-size: 15px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  h3{
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
    font-family: Maru Folk Bold;
    span{
      display: inline-block;
      position: relative;
      &:before, &:after{
        display: block;
        content: "";
        width: 32px;
        height: 8px;
        background-image: url("@{path-svg}mark.svg");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 6px;
      }
      &:before{
        left: -35px;
      }
      &:after{
        right: -35px;
      }
    }
  }
}
  .figure-img(@name,@bottom){
  width: 100vw;
  height: calc(100vw * 414/750);
  background-color: @color-light-gray;
  background-image: url("@{path-sm-img}@{name}.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: @bottom;
}
  .blackboard(){
  width: 100%;
  background-color: @color-black-blackboard;
  padding: 0 25px;
  box-sizing: border-box;
  figure.line{
    width: 100%;
    height: 6px;
    background-image: url("@{path-img-lg}line.png");
    background-size: auto 6px;
    background-repeat: repeat-x;
    background-position: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
}

@import '../_include/variables';
@import '../_include/decoration';


@media screen and (min-width: @responsive-threshold){
  section.memory{
    margin-bottom: 120px;
    padding-top: 30px;
    h2{
      figure{
        .lg.h2_logo(523px, 70px, 2);
      }
    }
    div.memory-inner{
      figure{
        width: 718px;
        height: 543px;
        background-image: url("@{path-img-lg}memories.png");
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 40px;
      }
      p{
        .lg.text-style();
      }
    }
  }
}

@media screen and (max-width: (@responsive-threshold - 1)){
  section.memory{
    margin-bottom: 90px;
    padding-top: 10px;
    h2{
      margin-bottom: 15px;
      figure{
        .sm.h2_logo(290px, 40px, 2);
      }
    }
    div.memory-inner{
      figure{
        width: 100vw;
        height: calc(100vw * 543/718);
        margin-left: -20px;
        background-image: url("@{path-img-lg}memories.png");
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: 30px;
      }
      p{
        .sm.text-style();
      }
    }
  }
}

@import (reference) "../variables";
@import (reference) "../tools";
@import (reference) "../relatives";

.lg {
  .h2_logo(@width,@height,@num) {
    width: @width;
    height: @height;
    background-image: url("@{path-svg}title@{num}.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  .text-style(@bottom:0) {
    font-size: 16px;
    line-height: 2;
    .line-height-fix-top(16px, 16px*2);
    .line-height-fix-bottom(16px, 16px*2, @bottom);
  }
  .about-style() {
    margin-bottom: 100px;
    p {
      .text-style(45px);
    }
    h3 {
      width: 100%;
      text-align: center;
      margin-bottom: 25px;
      font-family: Maru Folk Bold;
      span {
        font-size: 30px;
        display: inline-block;
        position: relative;
        &:before, &:after {
          display: block;
          content: "";
          width: 45px;
          height: 10px;
          background-image: url("@{path-svg}mark.svg");
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          top: 10px;
        }
        &:before {
          left: -50px;
        }
        &:after {
          right: -53px;
        }
      }
    }
  }
  .figure-img(@name,@bottom,@deg) {
    width: 700px;
    height: 390px;
    background-color: @color-light-gray;
    background-image: url("@{path-pc-img}@{name}.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: @bottom;
    border: 6px solid @color-white;
    border-radius: 3px;
    box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.6);
    transform: rotate(@deg);
  }
  .section_style() {
    h2 {
      margin-bottom: 20px;
    }
  }
  .blackboard() {
    width: 600px;
    background-color: @color-black-blackboard;
    margin: 0 auto;
    border-radius: 3px;
    box-shadow: 3px -1px 0 1px rgba(0, 0, 0, 0.5);
    figure.line {
      width: 446px;
      height: 6px;
      background-image: url("@{path-img-lg}line.png");
      background-size: 446px 6px;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 50px 0;
    }
  }
}

@import '_include/variables';
@import '_include/decoration';


body{
  //font-family: Maru Folk Regular;
  //font-family: Maru Folk Bold;
  font-family: Maru Folk Medium;
  color: @color-black;
  background-color: @color-base-yellow;
  @media screen and (min-width: @responsive-threshold){
  width: 100%;
  .document-wrapper{
    padding-top: 100vh;
    overflow: hidden;
    span.top-border.fixed{
      position: fixed;
      top: 0;
      left: 0;
    }
    span.top-border, span.bottom-border{
      display: block;
      width: 100vw;
      height: 6px;
      background-color: @color-line-blue;
      position: absolute;
      z-index: 1;
    }
    span.bottom-border{
      box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.7);
    }
    main{
      display: block;
      overflow: hidden;
      width: 1024px;
      margin: 0 auto;
      background-color: @color-base-yellow;
      box-sizing: border-box;
      position: relative;
      color: @color-black;
      div.contents{
        width: 756px;
        float: right;
        border-right: 6px solid @color-line-green;
        border-left: 6px solid @color-line-green;
        background-image: url("@{path-img-lg}bg.png");
        background-repeat: repeat;
        background-size: auto;
        background-position: left top;
        div.inner-content {
          width: 100%;
          box-sizing: border-box;
          padding: 0 30px;
          section {
            .lg.section_style();
          }
        }
      }
    }
  }
}
  @media screen and (max-width: (@responsive-threshold - 1)){
    .document-wrapper{
      overflow: hidden;
      span.top-border, span.bottom-border{
        display: block;
        width: 100vw;
        height: 3px;
        background-color: @color-line-blue;
        position: relative;
        z-index: 1;
      }
      span.bottom-border{
        box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.7);
      }
      main#main{
        display: block;
        position: relative;
        div.contents{
          background-image: url("@{path-img-lg}bg.png");
          background-repeat: repeat;
          background-size: auto;
          background-position: left top;
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
}

@media screen and (min-width: @responsive-threshold){
  .sm-only{
    display: none !important;
  }
}

@media screen and (max-width: (@responsive-threshold - 1)){
  .lg-only{
    display: none !important;
  }
}

//iphoneSE用
@media screen and (min-width:321px){
  .SE-only{
    display: none !important;
  }
}

@import '_section/top.less';
@import '_section/nav.less';
@import '_section/about.less';
@import '_section/memory.less';
@import '_section/menu.less';
@import '_section/info.less';
@import '_section/media.less';
@import '_section/news.less';
@import '_section/recruit.less';
@import '_section/photo.less';
@import '_section/sns.less';
@import '_section/footer.less';

@import '../_include/variables';
@import '../_include/decoration';

@media screen and (min-width: @responsive-threshold){
  section.recruit{
    margin-bottom: 120px;
    padding-top: 30px;
    h2 {
      figure {
        .lg.h2_logo(180px, 70px, 9);
      }
    }
    div.recruit-inner{
      .blackboard{
        .lg.blackboard();
        box-sizing: border-box;
        padding-left: 80px;
        padding-right: 80px;
      }
      p{
        line-height: 2;
        font-size: 16px;
        color: @color-white;
      }
    }
}
}

@media screen and (max-width: (@responsive-threshold - 1)) {
  section.recruit{
    margin-bottom: 90px;
    padding-top: 10px;
    h2 {
      margin-bottom: 15px;
      figure {
        .sm.h2_logo(105px, 45px, 9);
      }
    }
    div.recruit-inner{
      .blackboard{
        .sm.blackboard();
        p{
          .sm.text-style();
          font-size: 13px;
          margin-top: 10px;
          margin-bottom: 10px;
          padding-left: 5px;
          padding-right: 5px;
          color: @color-white;
        }
      }
    }
  }
}

@import '../_include/variables';
@import '../_include/decoration';

@media screen and (min-width: @responsive-threshold){
  section.photo{
    margin-bottom: 120px;
    padding-top: 30px;
    h2{
      figure{
        .lg.h2_logo(375px, 70px, 7);
      }
    }
    .photo-inner{
      .photogallery{
        font-size: 0;
        width: 630px;
        margin-left: auto;
        margin-right: auto;
        a{
          display: inline-block;
          width: 180px;
          height: 120px;
          padding: 8px;
          box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
          background-color: @color-white;
          border-radius: 3px;
          margin-bottom: 20px;
          cursor: pointer;
          img{
            width: 100%;
            height: 100%;
          }
          &:nth-child(3n-1){
            margin-right: 20px;
            margin-left: 20px;
          }
          &:nth-of-type(even):hover{
            transform: rotate(-1deg);
          }
          &:nth-of-type(odd):hover{
            transform: rotate(1deg);
          }
        }
      }
    }
  }
}

@media screen and (max-width: (@responsive-threshold - 1)){
  section.photo{
    margin-bottom: 70px;
    padding-top: 10px;
    h2{
      margin-bottom: 15px;
      figure{
        .sm.h2_logo(240px, 45px, 7);
      }
    }
    .photo-inner{
      .photogallery{
        font-size: 0;
        a{
          display: inline-block;
          width: calc((100vw - 40px - 20px) / 3);
          height: calc(((100vw - 40px - 20px) / 3) * (69/104));
          margin-bottom: 23px;
          img{
            width: 100%;
            height: 100%;
          }
          &:nth-child(3n-1){
            margin-right: 10px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

@import '../_include/variables';
@import '../_include/decoration';

@media screen and (min-width: @responsive-threshold) {
  section.media {
    margin-bottom: 120px;
    padding-top: 30px;
    h2 {
      figure {
        .lg.h2_logo(370px, 70px, 5);
      }
    }
    h2 + figure {
      .lg.figure-img("pic_media", 20px, 1.2deg);
    }
    div.media-list {
      ul {
        margin-top: 40px;
        li {
          font-size: 18px;
          position: relative;
          padding-left: 18px;
          margin-bottom: 15px;
          &:before {
            display: block;
            content: "";
            width: 8px;
            height: 8px;
            border: 2px solid @color-red;
            position: absolute;
            border-radius: 50%;
            top: 4px;
            left: 0;
          }
          a {
            color: @color-black;
          }
        }
        div.rest-wrapper {
          overflow: hidden;
          transition-duration: 0.2s;
          transition-property: height;
        }
      }
      span.btn-more {
        position: relative;
        top: -5px;
        border-bottom: 1px solid @color-black;
        font-size: 14px;
        margin-left: 20px;
        padding: 0;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: (@responsive-threshold - 1)) {
  section.media {
    margin-bottom: 90px;
    padding-top: 10px;
    h2 {
      margin-bottom: 15px;
      figure {
        .sm.h2_logo(230px, 45px, 5);
      }
    }
    h2 + figure {
      width: 100vw;
      .sm.figure-img("pic_media", 30px);
      margin-left: -20px;
      margin-bottom: 30px;
    }
    div.media-list {
      ul {
        margin-top: 30px;
        li {
          a{
            color: @color-black;
          }
          font-size: 13px;
          line-height: 1.5;
          position: relative;
          margin-bottom: 5px;
          font-family: Maru Folk Regular;
          padding-left: 15px;
          &:before {
            display: block;
            content: "";
            width: 3px;
            height: 3px;
            border: 2px solid @color-red;
            position: absolute;
            border-radius: 50%;
            top: 8px;
            left: 0;
          }
        }
        div.rest-wrapper {
          overflow: hidden;
          transition-duration: 0.2s;
          transition-property: height;
        }
      }
      span.btn-more {
        font-family: Maru Folk Regular;
        border-bottom: 1px solid @color-black;
        font-size: 12px;
        margin-left: 20px;
      }
    }
  }
}
